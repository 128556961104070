<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h4>Add User</h4>
            </b-col>
          </b-row>

          <b-row class="h-100" v-if="state !== 'show'" align-h="center">
            <b-spinner label="Loading..."></b-spinner>
          </b-row>
          
          <b-form v-if="state === 'show'">
            <b-row>
              <b-col cols="4">
                <label>Name <span class="text-red">*</span></label>
                <b-form-input v-model="$v.userData.name.$model" 
                              @blur="$v.userData.name.$touch()"></b-form-input>
                <div v-if="$v.userData.name.$error" class="text-red font-weight-400 text-left">
                  <p v-if="!$v.userData.name.required">This is a required field</p>
                </div>
              </b-col>
              <b-col cols="4">
                <label>Surname <span class="text-red">*</span></label>
                <b-form-input v-model="$v.userData.surname.$model" 
                              @blur="$v.userData.surname.$touch()"></b-form-input>
                <div v-if="$v.userData.surname.$error" class="text-red font-weight-400 text-left">
                  <p v-if="!$v.userData.surname.required">This is a required field</p>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <label>Contact Number</label>
                <b-form-input v-model="$v.userData.contactNumber.$model" 
                              @blur="$v.userData.contactNumber.$touch()"></b-form-input>
                <div v-if="$v.userData.contactNumber.$error" class="text-red font-weight-400 text-left">
                  <p v-if="!$v.userData.contactNumber.minLength || !$v.userData.contactNumber.maxLength || !$v.userData.contactNumber.numeric">Please enter a valid number</p>
                </div>
              </b-col>
              <b-col cols="4">
                <label>Email <span class="text-red">*</span></label>
                <b-form-input v-model="$v.userData.email.$model" 
                              @blur="$v.userData.email.$touch()"></b-form-input>
                <div v-if="$v.userData.email.$error" class="text-red font-weight-400 text-left">
                  <p v-if="!$v.userData.email.required">This is a required field</p>
                  <p v-if="!$v.userData.email.email">Please enter a valid email</p>
                </div>
              </b-col>
            </b-row>
           <b-row>
             <b-col cols="4">
               <label>Company</label>
               <b-form-select v-model="userData.nextecCompanyId" @change="companySelected()">
                 <b-form-select-option v-for="(item, index) in companiesList" :key="index" :value="item.key">{{item.value}}</b-form-select-option>
               </b-form-select>
             </b-col>
             <b-col cols="4">
               <label>Region</label>
               <b-form-select v-model="userData.nextecRegionId" :disabled="regionsList.length < 1" @change="roleSelected">
                 <b-form-select-option v-for="(item, index) in regionsList" :key="index" :value="item.key">{{item.value}}</b-form-select-option>
               </b-form-select>
             </b-col>
             <b-col cols="4">
               <label>Office</label>
               <b-form-select v-model="userData.nextecOfficeId" :disabled="officesList.length < 1" @change="roleSelected">
                 <b-form-select-option v-for="(item, index) in officesList" :key="index" :value="item.key">{{item.value}}</b-form-select-option>
               </b-form-select>
             </b-col>
           </b-row>
           
           <b-row>
             <b-col cols="4">
               <label>Role</label>
               <b-form-select v-model="userData.roleID" @change="roleSelected">
                 <b-form-select-option v-for="(item, index) in roles" :key="index" :value="item.roleID">{{item.description}}</b-form-select-option>
               </b-form-select>
             </b-col>
             <b-col cols="4" v-if="userData.roleID !== 1 && userData.roleID !== 0">
               <label>Supervisor</label>
               <b-form-select v-model="userData.supervisorId">
                 <b-form-select-option v-for="(item, index) in supervisors" :key="index" :value="item.userId">{{item.name}} {{item.surname}}</b-form-select-option>
               </b-form-select>
             </b-col>
             <b-col cols="4">
               <label>Manager</label>
                <b-input-group>
                    <b-form-input v-model="userData.managerName" :disabled="true"></b-form-input>
                    <b-input-group-append>
                        <b-button size="sm"  text="Button" variant="secondary" @click="openManagerSearchModal">Search</b-button>
                    </b-input-group-append>
                </b-input-group>
             </b-col>
           </b-row>
           <hr class="mx-3">
           <b-row>
             <b-col>
               <b-form-group label="Select rights of access for the user:">
                <b-form-checkbox-group
                  v-model="userData.accessRights">
                  <b-row class="m-0 p-0">
                    <b-col cols="2" class="m-0 p-0" v-for="(item, index) in accessRights" :key="index">
                      <b-form-checkbox :value="item">{{item.value}}</b-form-checkbox>
                    </b-col>
                  </b-row>
                  
                </b-form-checkbox-group>
              </b-form-group>

             </b-col>
           </b-row>
            <hr class="mx-3">
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button variant="red" squared @click="goBackToSearch" class="ml-2">Cancel</b-button>
                  </div>
                  <div>
                    <b-button variant="primary" squared @click="addNewUser" class="ml-2" :disabled="$v.userData.$invalid">Save</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form>
          
        </b-card>
      </b-col>
    </b-row>
    
    <b-modal id="search-user-modal" size="xl" hide-footer no-header @close="closeSearchUserModal">
      <b-row>
        <b-col cols="4">
          <label>Name</label>
          <b-form-input v-model="user.name"></b-form-input>
        </b-col>
        <b-col cols="4">
          <label>Surname</label>
          <b-form-input v-model="user.surname"></b-form-input>
        </b-col>
        <b-col cols="4">
          <label>Email</label>
          <b-form-input v-model="user.email"></b-form-input>
        </b-col>
      </b-row>

      <hr class="mx-3">
      <b-row>
        <b-col class="text-right">
          <b-button variant="red" class="ml-2" squared @click="clearUsersFilter" >Cancel</b-button>
          <b-button variant="primary" squared @click="doSearchUsers" class="ml-2">Search</b-button>
        </b-col>
      </b-row>
        
      <b-row>
        <b-col class="12">
          <b-table striped hover 
            :items="tableDataUsers.dataSource" 
            :fields="tableDataUsers.tableColumns"
            :busy="tableDataUsers.isLoading" 
            @row-clicked="chooseUser"
            :per-page="tableDataUsers.resultsPerPage"
            id="userSearch"
            :current-page="tableDataUsers.currentPage" sort-icon-left>

            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
              </div>
            </template>

            <template #cell(actions)="row">
              <b-row align-v="center" align-h="end">
                <b-button @click="chooseUser(row.item)" size="sm" class="btn-icon">
                  <b-icon-chevron-right></b-icon-chevron-right>
                </b-button>
              </b-row>
            </template>
          </b-table>
        </b-col>
      </b-row>
      
        <b-row align-h="center" >
            <b-pagination
                v-model="tableDataUsers.currentPage"
                :total-rows="selectedUser"
                :per-page="tableDataUsers.resultsPerPage"
                aria-controls="userSearch"
            ></b-pagination>
        </b-row>
        <b-row>
            <b-col class="text-right">
                <b-button variant="red" class="mr-2" squared @click="closeSearchUserModal">Cancel</b-button>
            </b-col>
        </b-row>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import {required, email, numeric} from "vuelidate/lib/validators";

export default {
  name: "createUser",
  data: () => ({
    state: 'show',
    userData: {
      userId: 0,
      name: null,
      surname: null,
      email: null,
      contactNumber: null,
      roleID: 0,
      accessRights: [],
      managerId: null,
      managerName: null,

      nextecCompanyId: 0,
      nextecRegionId: 0,
      nextecOfficeId: 0,
      supervisorId: 0

    },
    roles: [],
    accessRights: [],
    companiesList: [],
    regionsList: [],
    officesList: [],
    supervisors: [],
    user: {
        name: null,
        surname: null,
        email: null
    },
    tableDataUsers: {
        resultsPerPage: 10,
        currentPage: 1,
        dataSource: [],
        isLoading: true,
        tableColumns: [
            {
            label: 'Name',
            key: 'name',
            sortable: true,
            tdClass: '',
            },
            {
            label: 'Surname',
            key: 'surname',
            sortable: true,
            tdClass: '',
            },
            {
            label: '',
            key: 'actions',
            sortable: false,
            tdClass: ''
            }
        ]
    }
  }),
  created() {
    this.userRole()
    this.setBreadcrumb([
      {
        text: 'User'
      },
      {
        text: 'Add New User'
      },
    ])
    
    this.getAccessModules()
    .then((res) => {
      this.accessRights = res.data

      if (this.accessRights.length > 0) {
        this.userData.accessRights = this.accessRights.filter((accessRight) => {
            const name = accessRight['value'].toLowerCase().trim()
            return name === "Complete Toolbox Talk".toLowerCase() || name === "Complete Checklist".toLowerCase()
        });
      }
    })

    this.getCompanyList()
    .then((res) => {
      this.companiesList = res.data
    })
  },
  methods: {
    ...mapActions(["createUser", "getUserRoles", 'searchUsersRequest', 'getAccessModules', 'getCompanyList', 'getRegionListForACL', 'getOfficeListForACL', 'getSupervisors']),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    ...mapActions('notificationService', ['addWarningMessage']),
    openManagerSearchModal(){
      this.$bvModal.show('search-user-modal')
      this.doSearchUsers()
    },
    closeSearchUserModal(){
      this.$bvModal.hide('search-user-modal')
    },
    chooseUser(rowItem){
      //console.log(rowItem)
      this.userData.managerId = rowItem.userId
      this.userData.managerName = rowItem.name + ' ' + rowItem.surname
      //console.log(this.userData)
      this.closeSearchUserModal()
    },
    doSearchUsers(){
        this.tableDataUsers.isLoading = true
  
        let request = []
        
        if (this.user.name != null) {
            request.push({'key': 'name', 'value': this.user.name})
        }
        if (this.user.surname != null) {
            request.push({'key': 'surname', 'value': this.user.surname})
        }
        if (this.user.email != null) {
            request.push({'key': 'email', 'value': this.user.email})
        }
        if (this.user.roleId != null) {
            request.push({'key': 'fkroleId', 'value': this.user.roleId})
        }
        
        this.$store.commit('setUserSearchRequest', request)
        
        this.searchUsersRequest()
        .then((request) => {
            this.tableDataUsers.dataSource = request.data
            
            this.tableDataUsers.isLoading = false
            this.state  = 'show'
        })
        .catch( () => {
            this.tableDataUsers.isLoading = false
        })
    },
    
    clearUsersFilter(){
      this.user = {
          name: null,
          surname: null,
          email: null
      }
    },
    companySelected(){
      this.$store.commit('setGetRegionRequest',this.userData.nextecCompanyId);
       this.getRegionListForACL()
      .then((res) => {
        this.regionsList = res.data
      }) 

      this.$store.commit('setGetOfficeRequest',this.userData.nextecCompanyId);
       this.getOfficeListForACL()
      .then((res) => {
        this.officesList = res.data
      })
        
    },

    addNewUser() {
      this.$store.commit('setUserCreateRequest', this.userData)
      this.state = 'loading'
      this.createUser()
          .then(() => {
            this.goBackToSearch()
          })
          .catch(() => {
            this.state = 'show'
          })
    },
    
    goBackToSearch(){
      this.$router.back()
    },

    userRole() {
      this.getUserRoles()
          .then((response) => {
            this.roles = response.data
          })
    },

    roleSelected(){
      if (this.userData.roleID !== 1 && this.userData.roleID !== 0) {
        this.getSupervisors()
        .then((res) => {
          let list = res.data.sort(function(a, b){
              let x = a.name.toLowerCase();
              let y = b.name.toLowerCase();
              if (x < y) {return -1;}
              if (x > y) {return 1;}
              return 0;
          });
          this.supervisors = list
        })
      } else {
        this.userData.supervisorId = 0
      }
    },
    
  },
  computed: {
    ...mapState([
      'selectedUser'
    ]),
    userRows(){
      return this.tableDataUsers.dataSource.length
    },
  },
  validations: {
    userData: {
      name: {required},
      surname: {required},
      email: {email, required},
      contactNumber: {numeric},
    },
  },
}
</script>

<style scoped>

</style>